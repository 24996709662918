<!-- 统一身份认证登录临时页面 -->
<template>
  <div />
</template>
<script>
import Cookies from 'js-cookie'
import { Toast } from 'vant'
export default {
  name: 'Transfer',
  components: {
    [Toast.name]: Toast
  },
  mounted() {
    /**
     *  将首页、待缴费、退费隐藏。之所以隐藏是适配迎新系统进入缴费平台，学宿费减免,
     *  首页项目是老生学宿费，新生学宿费定制页面
     *  订单不隐藏z
     */
    window.localStorage.setItem('showHeadFlag', false)
    this.handleGoToDkPayment()
  },
  methods: {
    handleGoToDkPayment() {
      const jsonData = this.$route.query.jsonData
      if (this.$route.query.goToURL === 'boundWechat') {
        window.localStorage.setItem('enterSource', 'wechat')
        window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
        window.localStorage.setItem('openId', JSON.parse(jsonData).openId)
        window.localStorage.setItem('subAppId', JSON.parse(jsonData).subAppId)
        window.localStorage.setItem('schoolCode', JSON.parse(jsonData).schoolCode)
        var model = JSON.parse(jsonData).mobel
        var projectId = JSON.parse(jsonData).projectid
        this.$router.push({ path: '/loginBinding', query: { projectId: projectId, model: model }})
        return false
      } else if (this.$route.query.goToURL === 'schoolLogin') {
        window.localStorage.setItem('schoolcode', JSON.parse(jsonData).schoolCode)
        this.$router.push({ path: '/' })
        return false
      }
      const token = this.$route.query.token
      if (token === undefined || token === '') {
        Toast('传入参数无效！')
        // this.$messageOK('error', '传入参数无效！')
        this.$router.push({ path: this.redirect || '/login' })
        return false
      }
      this.$store.dispatch('user/refreshToken', token).then(() => {
        console.log(JSON.parse(jsonData).idserial)
        window.localStorage.setItem('schoolcode', JSON.parse(jsonData).schoolCode)
        // console.log(this.$route.query.goToURL)
        // console.log(this.$route.query.token)
        Cookies.set('datalook_reimbursement_token', this.$route.query.token)
        this.$store.dispatch('user/getInfo')
        this.$store.state.user.accountNum = JSON.parse(jsonData).idserial
        window.localStorage.setItem('accountNum', JSON.parse(jsonData).idserial)
        if (this.$route.query.goToURL === 'openapinameImport') {
          window.localStorage.setItem('openId', JSON.parse(jsonData).openId)
          window.localStorage.setItem('schoolCode', JSON.parse(jsonData).schoolCode)
          this.$router.push({ path: '/balanceInquiry', query: { accountNum: JSON.parse(jsonData).idserial }})
        } else if (this.$route.query.goToURL === 'homeWechat') {
          window.localStorage.setItem('enterSource', 'wechat')
          window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
          window.localStorage.setItem('openId', JSON.parse(jsonData).openId)
          window.localStorage.setItem('subAppId', JSON.parse(jsonData).subAppId)
          this.$router.push({ path: '/home' })
        } else if (this.$route.query.goToURL === 'homeCoWechat') {
          window.localStorage.setItem('enterSource', 'CoWechat')
          window.localStorage.setItem('agentId', JSON.parse(jsonData).agentId)
          window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
          window.localStorage.setItem('schoolCode', JSON.parse(jsonData).schoolCode)
          var model = JSON.parse(jsonData).mobel
          var projectId = JSON.parse(jsonData).projectid
          if (model !== '' && model !== null && model !== undefined && projectId !== '' && projectId !== null && projectId !== undefined) {
            this.$router.push({ path: model + 'Pay', query: { projectId: projectId }})
          } else {
            this.$router.push({ path: '/home' })
          }
        } else if (this.$route.query.goToURL === 'treeLogin') {
          // window.localStorage.getItem('schoolcode') = JSON.parse(jsonData).schoolCode
          window.localStorage.setItem('schoolcode', JSON.parse(jsonData).schoolCode)
          this.$router.push({ path: '/home' })
          window.localStorage.setItem('agentId', JSON.parse(jsonData).agentId)
          window.localStorage.setItem('openId', JSON.parse(jsonData).openid)
          window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
          window.localStorage.setItem('k12token', JSON.parse(jsonData).k12token)
        } else if (this.$route.query.goToURL === 'ecardLogin') {
          window.localStorage.setItem('enterSource', null)
          window.localStorage.setItem('enter', 'bocWechat')
          window.localStorage.setItem('openId', JSON.parse(jsonData).openid)
          this.$router.push({ path: '/home' })
        } else if (this.$route.query.goToURL === 'openapicommon') {
          this.$router.push({ path: '/commonPay', query: { projectId: JSON.parse(jsonData).projectId, accountNum: JSON.parse(jsonData).idserial }})
        }
        // if (this.$route.query.goToURL === 'openapituitionAndDorm') {
        //   this.$router.push({ path: '/tuitionAndDormPay', query: { projectId: JSON.parse(jsonData).projectId, accountNum: JSON.parse(jsonData).idserial }})
        // } else if (this.$route.query.goToURL === 'openapinameImport') {
        //   this.$router.push({ path: '/balanceInquiry', query: { projectId: JSON.parse(jsonData).projectId, accountNum: JSON.parse(jsonData).idserial }})
        // }
      })
    }
  }
}
</script>
